import Ajv, { JSONSchemaType } from 'ajv';
import { default as ajvErrors } from 'ajv-errors';

const ajv = new Ajv({ allErrors: true });
ajvErrors(ajv);

ajv.addKeyword({
  keyword: 'valueMissing',
  type: ['number', 'null'],
  errors: false,
  validate: (schema: JSONSchemaType<unknown>, data: number | null) => {
    if (schema) {
      if (data === null) {
        return false;
      }
    }
    return true;
  },
});

export default ajv;
