import { isEmpty, isEqual } from 'lodash';

import { AnswerUpdateDto } from '@/app/services/answers';
import { IAnswer, IAnswerEdit, IChoice, IQuestion } from '@/interfaces';
import { IAnswerError } from '@/interfaces/AnswerError';
import { IAssessmentOne } from '@/interfaces/AssessmentOne';
import { IAssessmentRequestOne } from '@/interfaces/AssessmentRequestOne';
import { QuestionType } from '@/types';
import {
  validateBooleanQuestion,
  validateChoiceQuestion,
  validateDropdownQuestion,
  validateMultipleChoiceQuestion,
  validateNumberOrTextQuestion,
} from '@/utils/validation';

export const composeAnswer = (
  currentAnswer: IAnswer | undefined,
  assessment: IAssessmentOne,
  question: IQuestion,
): IAnswerEdit => {
  let ret: IAnswerEdit;
  if (currentAnswer) {
    ret = {
      ...currentAnswer,
      isChanged: false,
      ...(currentAnswer.documentUrl ? { documentName: currentAnswer.documentUrl } : {}),
    };
  } else {
    const initialChoicesValue: {
      [key: string]: {
        checked: boolean;
        numberValue: number | null;
        textValue: string | null;
      };
    } = {};
    if (question.questionType === QuestionType.MULTIPLE_CHOICE) {
      question.choices?.forEach((item) => {
        initialChoicesValue[item] = {
          checked: false,
          numberValue: null,
          textValue: null,
        };
      });
    }
    ret = {
      id: undefined,
      version: undefined,
      year: assessment.assessmentRequest.year,
      questionType: question.questionType,
      answerableId: undefined,
      isFinalized: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      questionId: question.id,
      companyId: undefined,
      textAnswer: { value: '' },
      numberAnswer: { value: undefined },
      booleanAnswer: { value: undefined },
      choiceAnswer: undefined,
      dropdownAnswer: undefined,
      multipleChoiceAnswer: { value: initialChoicesValue },
      documents: [],
      documentUrl: undefined,
      explanation: undefined,
      isChanged: false,
    };
  }
  return ret;
};

export const composeRemovedAnswer = (
  currentAnswer: IAnswer | undefined,
  question: IQuestion,
  assessmentRequest: IAssessmentRequestOne,
): IAnswerEdit => {
  let ret: IAnswerEdit;
  const initialChoicesValue: {
    [key: string]: {
      checked: boolean;
      numberValue: number | null;
      textValue: string | null;
    };
  } = {};
  if (question.questionType === QuestionType.MULTIPLE_CHOICE) {
    question.choices?.forEach((item) => {
      initialChoicesValue[item] = {
        checked: false,
        numberValue: null,
        textValue: null,
      };
    });
  }

  if (!isEmpty(currentAnswer)) {
    ret = {
      ...currentAnswer,
      textAnswer: { value: '' },
      numberAnswer: { value: undefined },
      booleanAnswer: { value: undefined },
      choiceAnswer: undefined,
      multipleChoiceAnswer: { value: initialChoicesValue },
      documents: [],
      documentUrl: undefined,
      explanation: undefined,
      isChanged: true,
    };
  } else {
    ret = {
      id: undefined,
      version: undefined,
      year: assessmentRequest.year,
      questionType: question.questionType,
      questionId: question.id,
      createdAt: undefined,
      updatedAt: undefined,
      answerableId: undefined,
      isFinalized: undefined,
      companyId: undefined,
      textAnswer: { value: '' },
      numberAnswer: { value: undefined },
      booleanAnswer: { value: undefined },
      choiceAnswer: undefined,
      dropdownAnswer: undefined,
      multipleChoiceAnswer: { value: initialChoicesValue },
      documents: [],
      documentUrl: undefined,
      explanation: undefined,
      isChanged: true,
    };
  }
  return ret;
};

export const hasAnswerChanged = (answer: IAnswer, editedAnswer: IAnswerEdit) => {
  const {
    booleanAnswer,
    documentUrl,
    choiceAnswer,
    dropdownAnswer,
    explanation,
    multipleChoiceAnswer,
    numberAnswer,
    textAnswer,
    documents,
  } = answer;
  const {
    booleanAnswer: booleanAnswerEdited,
    documentUrl: documentUrlEdited,
    choiceAnswer: choiceAnswerEdited,
    dropdownAnswer: dropdownAnswerEdited,
    explanation: choiceExplanationEdited,
    multipleChoiceAnswer: multipleChoiceAnswerEdited,
    numberAnswer: numberAnswerEdited,
    textAnswer: textAnswerEdited,
    documents: documentsEdited,
  } = editedAnswer;

  if (
    booleanAnswer?.value !== booleanAnswerEdited?.value ||
    documentUrl !== documentUrlEdited ||
    choiceAnswer?.choice.id !== choiceAnswerEdited?.choice.id ||
    explanation !== choiceExplanationEdited ||
    dropdownAnswer?.dropdownItem.id !== dropdownAnswerEdited?.dropdownItem.id ||
    !isEqual(multipleChoiceAnswer, multipleChoiceAnswerEdited) ||
    numberAnswer?.value !== numberAnswerEdited?.value ||
    textAnswer?.value !== textAnswerEdited?.value ||
    documents.length !== documentsEdited.length
  ) {
    return true;
  }

  const docIds = documents?.map(({ id }) => id);
  const docIdsEdited = documentsEdited?.map(({ id }) => id);
  const hasNewDocuments = docIdsEdited?.some((idEdited) => docIds.indexOf(idEdited) === -1);
  if (hasNewDocuments) {
    return true;
  }

  return false;
};

export const prepareAnswerUpdates = (
  currentQuestion: IQuestion | null,
  currentQuestionAnswers: { [key: string]: IAnswerEdit },
  answers: { [key: string]: IAnswer },
  childQuestions: { [key: string]: IQuestion },
  questions: { [key: string]: IQuestion },
): AnswerUpdateDto => {
  const updates: AnswerUpdateDto = {};

  if (!currentQuestion) {
    return updates;
  }

  const inserted: IAnswerEdit[] = [];
  const updated: IAnswerEdit[] = [];
  const deleted: string[] = [];
  // main question
  const mainQuestionAnswer = currentQuestionAnswers[currentQuestion.id];

  const hasMainAnswer = !!mainQuestionAnswer && currentQuestion.questionType !== QuestionType.GROUP;

  if (hasMainAnswer) {
    const mainQuestionOldAnswer = mainQuestionAnswer.id ? answers[mainQuestionAnswer.id] : null;

    if (!mainQuestionOldAnswer) {
      inserted.push(mainQuestionAnswer);
    } else if (hasAnswerChanged(mainQuestionOldAnswer, mainQuestionAnswer)) {
      updated.push(mainQuestionAnswer);
    }
  }

  const collectAllDescendantQuestionIds = (
    question: IQuestion,
    collectedIds: Set<string> = new Set(),
  ) => {
    if (!question) return;
    if (collectedIds.has(question.id)) return;
    collectedIds.add(question.id);

    question.childQuestions?.forEach((childQuestionId) => {
      const childQuestion = childQuestions[childQuestionId];
      if (childQuestion) {
        collectAllDescendantQuestionIds(childQuestion, collectedIds);
      }
    });

    question.groupQuestions?.forEach((groupQuestion) => {
      const groupQuestionItem = questions[groupQuestion.questionId];

      if (groupQuestionItem) {
        collectAllDescendantQuestionIds(groupQuestionItem, collectedIds);
      }
    });
  };

  const allDescendantQuestionIdsSet = new Set<string>();
  collectAllDescendantQuestionIds(currentQuestion, allDescendantQuestionIdsSet);

  allDescendantQuestionIdsSet.delete(currentQuestion.id);

  // collect child questions
  const answerValues = Object.values(currentQuestionAnswers)?.filter((item) =>
    allDescendantQuestionIdsSet.has(item.questionId),
  );

  for (const answer of answerValues) {
    if (answer.id) {
      if (
        hasMainAnswer &&
        mainQuestionAnswer.questionType === QuestionType.BOOLEAN &&
        mainQuestionAnswer.booleanAnswer?.value !==
          childQuestions[answer.questionId].showOnParentBooleanValue
      ) {
        deleted.push(answer.id);
      } else if (answer.questionType === QuestionType.DROPDOWN && !answer.dropdownAnswer) {
        deleted.push(answer.id);
      } else {
        const oldAnswer = answers[answer.id];
        if (hasAnswerChanged(oldAnswer, answer)) {
          updated.push(answer);
        }
      }
    } else {
      if (
        (answer.questionType === QuestionType.BOOLEAN &&
          answer.booleanAnswer?.value !== undefined) ||
        (answer.questionType === QuestionType.CHOICE && answer.choiceAnswer?.choice.id) ||
        (answer.questionType === QuestionType.MULTIPLE_CHOICE &&
          answer.multipleChoiceAnswer?.value &&
          Object.values(answer.multipleChoiceAnswer?.value).find((mc) => mc.checked)) ||
        (answer.questionType === QuestionType.NUMBER && answer.numberAnswer?.value !== undefined) ||
        (answer.questionType === QuestionType.TEXT && answer.textAnswer?.value) ||
        (answer.questionType === QuestionType.DROPDOWN && answer.dropdownAnswer?.dropdownItem.id)
      ) {
        inserted.push(answer);
      }
    }
  }
  if (inserted.length > 0) {
    updates['insert'] = inserted;
  }

  if (updated.length > 0) {
    updates['update'] = updated;
  }

  if (deleted.length > 0) {
    updates['delete'] = deleted;
  }

  return updates;
};

export const getRelevantAnswers = (
  currentQuestion: IQuestion | null,
  currentQuestionAnswers: { [key: string]: IAnswerEdit },
  childQuestions: { [key: string]: IQuestion },
): IAnswerEdit[] => {
  if (!currentQuestion) {
    return [];
  }

  const currentAnswer = currentQuestionAnswers[currentQuestion.id];
  if (!currentAnswer) {
    return [];
  }

  const hasNoChildrenOrGroups =
    !currentQuestion.childQuestions?.length && !currentQuestion.groupQuestions?.length;
  if (hasNoChildrenOrGroups) {
    return [currentAnswer];
  }

  const getCheckedMultipleChoiceIds = (answer: IAnswerEdit): string[] => {
    const multipleChoiceValues = answer.multipleChoiceAnswer?.value ?? {};
    return Object.entries(multipleChoiceValues)
      .filter(([_, value]) => value.checked)
      .map(([id]) => id);
  };

  const isValidBooleanCheck = (
    childQuestion: IQuestion,
    booleanValue: boolean | null | undefined,
  ): boolean =>
    childQuestion.showOnParentBooleanValue === booleanValue &&
    booleanValue !== undefined &&
    booleanValue !== null;

  const isValidChoiceCheck = (childQuestion: IQuestion, choiceId: string): boolean =>
    childQuestion.showOnParentChoiceValue?.includes(choiceId) ?? false;

  const isValidMultipleChoiceCheck = (childQuestion: IQuestion, checkedIds: string[]): boolean =>
    checkedIds.some((id) => childQuestion.showOnParentChoiceValue?.includes(id));

  const shouldIncludeChildQuestion = (childQuestion: IQuestion): boolean => {
    switch (currentQuestion.questionType) {
      case QuestionType.BOOLEAN:
        return isValidBooleanCheck(childQuestion, currentAnswer.booleanAnswer?.value);

      case QuestionType.CHOICE:
        return isValidChoiceCheck(childQuestion, currentAnswer.choiceAnswer?.choice.id ?? '');

      case QuestionType.MULTIPLE_CHOICE:
        return isValidMultipleChoiceCheck(
          childQuestion,
          getCheckedMultipleChoiceIds(currentAnswer),
        );

      default:
        return false;
    }
  };

  const childAnswers = (currentQuestion.childQuestions ?? [])
    .map((childId) => childQuestions[childId])
    .filter(shouldIncludeChildQuestion)
    .map((childQuestion) => currentQuestionAnswers[childQuestion.id])
    .filter(Boolean);

  const groupAnswers = (currentQuestion.groupQuestions || [])
    .map((g) => currentQuestionAnswers[g.questionId])
    .filter(Boolean);

  return [currentAnswer, ...childAnswers, ...groupAnswers];
};

export const validateAnswers = (
  currentQuestion: IQuestion | null,
  currentQuestionAnswers: { [key: string]: IAnswerEdit },
  choices: { [key: string]: IChoice },
  childQuestions: { [key: string]: IQuestion },
  questions: { [key: string]: IQuestion },
) => {
  const errorList: IAnswerError = {};
  const relevantAnswers = getRelevantAnswers(
    currentQuestion,
    currentQuestionAnswers,
    childQuestions,
  );

  if (!currentQuestion) {
    return {};
  }

  const relevantQuestions: { [key: string]: IQuestion } = {
    [currentQuestion.id]: currentQuestion,
  };

  (currentQuestion.childQuestions || [])
    .map((id) => childQuestions[id])
    .forEach((q) => {
      relevantQuestions[q.id] = q;
    });

  (currentQuestion.groupQuestions || [])
    .map((g) => questions[g.questionId])
    .forEach((q) => {
      relevantQuestions[q.id] = q;
    });

  relevantAnswers.forEach((answer) => {
    const question = relevantQuestions[answer.questionId];

    if (!question) {
      return; // Skip if the question is not found
    }

    switch (answer.questionType) {
      case QuestionType.BOOLEAN:
        validateBooleanQuestion(answer, question, errorList);
        break;
      case QuestionType.CHOICE:
        validateChoiceQuestion(answer, question, errorList);
        break;
      case QuestionType.DROPDOWN:
        validateDropdownQuestion(answer, question, errorList);
        break;
      case QuestionType.MULTIPLE_CHOICE:
        validateMultipleChoiceQuestion(answer, errorList, choices);
        break;
      case QuestionType.NUMBER:
      case QuestionType.TEXT:
        validateNumberOrTextQuestion(answer, question, errorList);
        break;
    }
  });

  return errorList;
};

export const getAreAllQuestionsAnswered = (
  questionValues: IQuestion[],
  answerValues: IAnswer[],
) => {
  if (questionValues.length === 0 || answerValues.length === 0) {
    return false;
  }

  return questionValues.every((question) => {
    // skip question if it is a group child
    // because it will be checked as part of group parent question
    if (question.groups.length !== 0) {
      return true;
    }

    if (question.questionType === QuestionType.GROUP) {
      return (question.groupQuestions || [])
        .filter((q) => !q.optional)
        .every((q) => answerValues.some((a) => a.questionId === q.questionId));
    }

    return answerValues.some((a) => a.questionId === question.id);
  });
};
