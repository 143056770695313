import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { NormalizedSchema } from 'normalizr';

import { IQuestion } from '@/interfaces';
import { RootState } from '@/types/RootState';
import { TypeToKey } from '@/types/TypeToKey';

import { clearCredentials } from '../../auth/authSlice';

export type QuestionsState = {
  ids: string[] | [];
  entities: TypeToKey<IQuestion>;
};

const initialState = {
  ids: [],
  entities: {
    questions: {},
  },
} as QuestionsState;

const slice = createSlice({
  name: 'adminQuestions',
  initialState,
  reducers: {
    setAdminQuestions: (
      state,
      { payload }: PayloadAction<NormalizedSchema<TypeToKey<IQuestion>, string[]>>,
    ) => {
      state.ids = payload.result;
      state.entities = payload.entities;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearCredentials, () => {
      return initialState;
    });
  },
});

export const { setAdminQuestions } = slice.actions;

export default slice.reducer;

export const selectAdminQuestions = (state: RootState) => state.adminQuestions.entities.questions;
export const selectAdminChoices = (state: RootState) => state.adminQuestions.entities.choices;
export const selectAdminChildQuestions = (state: RootState) =>
  state.adminQuestions.entities.childQuestions;
