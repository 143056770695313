import { Schema } from 'ajv';
import { forOwn, isEmpty } from 'lodash';

import { IAnswerError, IAnswerErrorValues } from '@/interfaces/AnswerError';
import { IAnswerEdit, IChoice, IQuestion } from '@/interfaces';
import ajv from '@/utils/ajv';

export function isValidHttpUrl(str: string): boolean {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i',
  );
  return pattern.test(str);
}

export function exhaustiveGuard(_value: never): never {
  throw new Error(
    `ERROR! Reached forbidden guard function with unexpected value: ${JSON.stringify(_value)}`,
  );
}

const schemaCache = new Map();

export function validateWithSchema(
  schema: Schema | null,
  _value: string | number | boolean | null | undefined,
) {
  if (!schema) {
    return;
  }
  let value = _value;
  if (typeof value !== 'number' && value !== null && !isNaN(Number(value))) {
    value = Number(value);
  }

  const schemaKey = JSON.stringify(schema);

  let validate = schemaCache.get(schemaKey);
  if (!validate) {
    validate = ajv.compile(schema);
    schemaCache.set(schemaKey, validate);
  }

  const isValid = validate(value);
  let error: string | null = null;

  if (isValid || !validate.errors || validate.errors.length === 0) {
    return {
      valid: true,
      error: null,
    };
  }

  error = validate.errors[0].keyword;

  const params = validate.errors[0].params;

  // we exclude all the comparison related errors, because we don't need too much precise atm
  if (isEmpty(params) || Object.hasOwn(params, 'comparison')) {
    return {
      valid: isValid,
      error,
    };
  }

  forOwn(params, (value) => (error = `${error!}.${value}`));

  return {
    valid: isValid,
    error,
  };
}

export function validateAnswerValue(
  validationRules: Schema | null,
  value: IAnswerEdit['explanation'] | number,
): IAnswerErrorValues | null {
  const schemaValidation = validateWithSchema(validationRules, value);

  if (schemaValidation?.error) {
    return {
      error: `error.${schemaValidation.error}` as IAnswerErrorValues['error'],
    };
  }
  return null;
}

export function validateNumberOrTextQuestion(
  answer: IAnswerEdit,
  question: IQuestion,
  errorList: IAnswerError,
) {
  const value = answer.numberAnswer?.value ?? answer.textAnswer?.value;

  const error = validateAnswerValue(question.validationRules, value);

  if (error) {
    errorList[answer.questionId] = error;
  }
}

export function validateBooleanQuestion(
  answer: IAnswerEdit,
  question: IQuestion,
  errorList: IAnswerError,
) {
  const err: IAnswerErrorValues = {};

  if (answer.booleanAnswer?.value === undefined || answer.booleanAnswer?.value === null) {
    err.error = 'error.chooseOne';
  }

  if (
    question.isDocumentRequired &&
    answer.booleanAnswer?.value &&
    (!answer.documents || answer.documents.length === 0) &&
    !answer.documentUrl
  ) {
    err.documentError = 'error.documentMissing';
  }

  if (Object.keys(err).length > 0) {
    errorList[answer.questionId] = err;
  }
}

export function validateChoiceQuestion(
  answer: IAnswerEdit,
  question: IQuestion,
  errorList: IAnswerError,
) {
  if (!answer.choiceAnswer) {
    errorList[answer.questionId] = { error: 'error.chooseOne' };
  } else if (answer.choiceAnswer.choice.requireExplanation && !answer.explanation) {
    errorList[answer.questionId] = { error: 'error.additionalInformationMissing' };
  } else if (
    answer.choiceAnswer.choice.requireExplanation &&
    answer.choiceAnswer.choice.validationRules
  ) {
    const error = validateAnswerValue(
      answer.choiceAnswer.choice.validationRules,
      answer.explanation ?? '',
    );

    if (error) {
      errorList[answer.questionId] = error;
    }
  }
}

export function validateDropdownQuestion(
  answer: IAnswerEdit,
  question: IQuestion,
  errorList: IAnswerError,
) {
  if (!answer.dropdownAnswer) {
    const error = validateAnswerValue(question.validationRules, answer.explanation || '');

    if (error) {
      errorList[answer.questionId] = error;
    }
  }

  if (
    answer.dropdownAnswer &&
    answer.dropdownAnswer.dropdownItem.requireExplanation &&
    !answer.explanation
  ) {
    errorList[answer.questionId] = { error: 'error.additionalInformationMissing' };
  }

  if (
    answer.dropdownAnswer &&
    answer.dropdownAnswer.dropdownItem.requireExplanation &&
    answer.dropdownAnswer.dropdownItem.validationRules
  ) {
    const error = validateAnswerValue(
      answer.dropdownAnswer.dropdownItem.validationRules,
      answer.explanation ?? '',
    );

    if (error) {
      errorList[answer.questionId] = error;
    }
  }
}

export function validateMultipleChoiceQuestion(
  answer: IAnswerEdit,
  errorList: IAnswerError,
  choices: { [key: string]: IChoice },
) {
  const multipleChoiceValue = answer.multipleChoiceAnswer?.value;

  const nothingChecked =
    multipleChoiceValue && Object.values(multipleChoiceValue).every((mc) => !mc.checked);

  if (!multipleChoiceValue || nothingChecked) {
    errorList[answer.questionId] = { error: 'error.checkOne' };
  } else {
    for (const id in multipleChoiceValue) {
      const choiceAnswer = multipleChoiceValue[id];
      if (!choiceAnswer.checked) {
        continue;
      }
      const choice = choices[id]; // Access choice from the choices parameter
      const value = choiceAnswer.numberValue ?? choiceAnswer.textValue;

      const error = validateAnswerValue(choice?.validationRules, value);

      if (error) {
        errorList[answer.questionId] = error;
        break; // Stop at the first validation error
      }
    }
  }
}
