export const LoggerContext = {
  assessments: 'Assessments',
  assessmentId: 'Assessment Id',
  assessmentSummary: 'Assessment Summary',
  assessmentRequest: 'Assessment Request',
  assessmentDetails: 'Assessment Details',
  assessmentList: 'Assessment List',
  assessmentRequestDetails: 'Assessment Request Details',
  assessmentRequestInsights: 'Assessment Request Insights',
  assessmentRequestSummary: 'Assessment Request Summary',
  assessmentRequestList: 'Assessment Request List',
  assessmentRequestAssessmentSummary: 'Assessment Request Assessment Summary',
  resetPassword: 'Reset Password',
  requesterAssessmentList: 'Requester Assessment List',
  answerDocumentList: 'Answer Document List',
  answerDocumentUpload: 'Answer Document Upload',
  auth: 'Auth',
  login: 'Login',
  chat: 'Chat',
  questions: 'Questions',
  verify: 'Verify',
  topicsByArea: 'Topics By Area',
  currentUserProvider: 'Current User Provider',
};
