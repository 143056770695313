import { useRouter } from 'next/router';

import { I18N_CONFIG } from '@/config';
import { Locale } from '@/common/enums';

export default function useLocale() {
  const { locale: _locale } = useRouter();

  const defaultLocale: Locale = I18N_CONFIG.defaultLocale as Locale;

  const locale: Locale = (_locale as Locale | undefined) ?? defaultLocale;

  return { locale, defaultLocale };
}
