import { createApi } from '@reduxjs/toolkit/query/react';
import { NormalizedSchema } from 'normalizr';

import { IQuestion } from '@/interfaces';
import { IChoice } from '@/interfaces/Choice';
import { TypeToKey } from '@/types';
import { normalizeResponse } from '@/utils/normalize';

import { choicesSchema, questionsSchema } from '../schema';

import { baseQueryWithRefresh } from './base';

interface FetchQuestionsRequest {
  assessmentId: string;
}

export const questionsApi = createApi({
  baseQuery: baseQueryWithRefresh,
  endpoints: (builder) => ({
    fetchQuestions: builder.mutation<
      NormalizedSchema<TypeToKey<IQuestion>, string[]>,
      FetchQuestionsRequest
    >({
      query: ({ assessmentId }) => ({
        url: `/questions?assessmentId=${assessmentId}`,
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: IQuestion[]) => {
        return normalizeResponse<IQuestion>(response, questionsSchema);
      },
    }),
    fetchAdminQuestions: builder.mutation<NormalizedSchema<TypeToKey<IQuestion>, string[]>, void>({
      query: () => ({
        url: '/admin-questions',
        method: 'GET',
        credentials: 'include',
      }),
      transformResponse: (response: IQuestion[]) => {
        return normalizeResponse<IQuestion>(response, questionsSchema);
      },
    }),
    createChoice: builder.mutation<
      NormalizedSchema<TypeToKey<IChoice>, string[]>,
      Omit<IChoice, 'id'>
    >({
      query: (body) => ({
        url: '/admin-choices',
        method: 'POST',
        credentials: 'include',
        body,
      }),
      transformResponse: (response: IChoice[]) => {
        return normalizeResponse<IChoice>(response, choicesSchema);
      },
    }),
  }),
  reducerPath: 'questionsApi',
});

export const {
  useFetchQuestionsMutation,
  useFetchAdminQuestionsMutation,
  useCreateChoiceMutation,
} = questionsApi;
