export enum SupportedFileType {
  BMP = 'bmp',
  CSV = 'csv',
  DOC = 'doc',
  DOCX = 'docx',
  GIF = 'gif',
  JPG = 'jpg',
  JPEG = 'jpeg',
  KEY = 'key',
  NUMBERS = 'numbers',
  ODT = 'odt',
  PAGES = 'pages',
  PDF = 'pdf',
  PNG = 'png',
  PPT = 'ppt',
  PPTX = 'pptx',
  TXT = 'txt',
  WEBP = 'webp',
  XLS = 'xls',
  XLSX = 'xlsx',
}

enum MimeType {
  BMP = 'image/bmp',
  CSV = 'text/csv',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  GIF = 'image/gif',
  JPEG = 'image/jpeg',
  KEY = 'application/vnd.apple.keynote',
  NUMBERS = 'application/vnd.apple.numbers',
  ODT = 'application/vnd.oasis.opendocument.text',
  PAGES = 'application/vnd.apple.pages',
  PDF = 'application/pdf',
  PNG = 'image/png',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  TXT = 'text/plain',
  WEBP = 'image/webp',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export const MimeTypesMap = new Map([
  [SupportedFileType.BMP, MimeType.BMP],
  [SupportedFileType.CSV, MimeType.CSV],
  [SupportedFileType.DOC, MimeType.DOC],
  [SupportedFileType.DOCX, MimeType.DOCX],
  [SupportedFileType.GIF, MimeType.GIF],
  [SupportedFileType.JPG, MimeType.JPEG],
  [SupportedFileType.JPEG, MimeType.JPEG],
  [SupportedFileType.KEY, MimeType.KEY],
  [SupportedFileType.NUMBERS, MimeType.NUMBERS],
  [SupportedFileType.ODT, MimeType.ODT],
  [SupportedFileType.PAGES, MimeType.PAGES],
  [SupportedFileType.PDF, MimeType.PDF],
  [SupportedFileType.PNG, MimeType.PNG],
  [SupportedFileType.PPT, MimeType.PPT],
  [SupportedFileType.PPTX, MimeType.PPTX],
  [SupportedFileType.TXT, MimeType.TXT],
  [SupportedFileType.WEBP, MimeType.WEBP],
  [SupportedFileType.XLS, MimeType.XLS],
  [SupportedFileType.XLSX, MimeType.XLSX],
]);
