import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';
import { Roboto } from 'next/font/google';

export const roboto = Roboto({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
});

declare module '@mui/material/styles' {
  interface Palette {
    progressColors: {
      green: string;
      yellow: string;
      red: string;
    };
  }
  interface PaletteOptions {
    progressColors: {
      green: string;
      yellow: string;
      red: string;
    };
  }
  interface BreakpointsOptions {
    values: {
      xs: number;
      sm: number;
      md: number;
      lg: number;
      xl: number;
      xxl: number;
    };
  }
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#277190',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
      dark: red.A100,
    },
    progressColors: {
      green: '#21A314',
      yellow: '#FFE500',
      red: '#FF4300',
    },
  },
  typography: {
    fontFamily: roboto.style.fontFamily,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
      xxl: 1928,
    },
  },
});

export default theme;
