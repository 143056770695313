import { NormalizedSchema, normalize, schema } from 'normalizr';

import { TypeToKey } from '@/types/TypeToKey';

export const normalizeResponse = <T>(
  responseData: T[],
  schema: schema.Array,
): NormalizedSchema<TypeToKey<T>, string[]> => {
  return normalize(responseData, schema);
};

export const normalizeResponseEntity = <T>(
  responseData: T,
  schema: schema.Entity,
): NormalizedSchema<TypeToKey<T>, string[]> => {
  return normalize(responseData, schema);
};
