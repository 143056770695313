import { ICompany } from '../interfaces/Company';
import { IUser } from '../interfaces/User';

declare const window: Window &
  typeof globalThis & {
    // eslint-disable-next-line
    gtag: any;
  };

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const logPageView = (url: string) => {
  window.gtag &&
    window.gtag('config', process.env.NEXT_PUBLIC_GA_ID || '', {
      page_path: url,
    });
};

// eslint-disable-next-line
export const sendEvent = (
  action: string,
  user?: IUser,
  company?: ICompany,
  params?: Record<string, unknown>,
) => {
  window.gtag &&
    window.gtag('event', action, {
      user_id: user?.id,
      user_first_name: user?.firstName,
      user_last_name: user?.lastName,
      user_phone: user?.phoneNumber,
      user_email: user?.email,
      company_id: company?.id,
      company_name: company?.name,
      company_registry_number: company?.registryNumber,
      company_nace_code: company?.naceCode,
      company_is_requester: company?.isRequester,
      ...params,
    });
};
